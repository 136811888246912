var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import useConfirmStore from '../stores/confirmStore';
import { computed, inject, provide, ref, toRefs, watch, triggerRef, onMounted, onUnmounted } from 'vue';
import { T, TextStyles, useServerMessagesService, useDnxServerTableList } from '@dnx/core';
import conversationsApi from './../../app/api-generated/recording';
import { refDebounced, useSessionStorage } from '@vueuse/core';
export const provideConversationOverviewData = () => {
    const filters = useSessionStorage('mangl-conversation-filters', {});
    const filterPageNumber = ref(1);
    const confirmStore = useConfirmStore();
    // const serverMessagesStore = useServerMessagesService();
    // ensure we have a valid (and watchable!) value
    // todo: check how `useLocalStorage` behaves in environments without localStorage available
    if (!filters.value.searchString)
        filters.value.searchString = '';
    const { searchString } = toRefs(filters.value);
    const searchStringDebounced = refDebounced(searchString, 300);
    const sanitizedFilters = computed(() => {
        const { agents, callCodes, sentiments, campaigns, qmQuestionScores, conversationDateEnd, conversationDateStart, analysisDateStart, analysisDateEnd, divisions, isCallSuccessful, statuses, tags, uploadDateEnd, 
        // searchString,
        uploadDateStart, } = filters.value;
        return {
            searchString: searchStringDebounced.value,
            agents: ((agents === null || agents === void 0 ? void 0 : agents.length) && agents) || undefined,
            callCodes: ((callCodes === null || callCodes === void 0 ? void 0 : callCodes.length) && callCodes) || undefined,
            sentiments: ((sentiments === null || sentiments === void 0 ? void 0 : sentiments.length) && sentiments) || undefined,
            campaigns: ((campaigns === null || campaigns === void 0 ? void 0 : campaigns.length) && campaigns) || undefined,
            divisions: ((divisions === null || divisions === void 0 ? void 0 : divisions.length) && divisions) || undefined,
            tags: ((tags === null || tags === void 0 ? void 0 : tags.length) && tags) || undefined,
            statuses: ((statuses === null || statuses === void 0 ? void 0 : statuses.length) && statuses) || undefined,
            conversationDateStart,
            conversationDateEnd,
            analysisDateStart,
            analysisDateEnd,
            isCallSuccessful,
            uploadDateStart,
            uploadDateEnd,
            qmQuestionScores,
        };
    });
    const conversationsTableList = useDnxServerTableList(conversationsApi.list, [sanitizedFilters], {
        pageSize: 50,
        // debounce: { wait: 250, leading: true },
    });
    const itemCount = computed(() => {
        return conversationsTableList.value.pagination.itemCount;
    });
    const itemTotal = computed(() => {
        return conversationsTableList.value.pagination.totalItemCount;
    });
    const conversationsData = computed(() => conversationsTableList.value.data);
    // omit `data` property... for integration with local search.... properly fix later on
    const conversationsTableProps = computed(() => Object.fromEntries(Object.entries(conversationsTableList.value).filter(([key]) => key !== 'data')));
    watch(filters, () => {
        //Manually set page to 1;
        conversationsTableList.setPage(1);
    }, { deep: true });
    // hack, to trigger a reload
    const fetchConversations = () => {
        triggerRef(filters);
    };
    const promptDeleteConversations = (ids) => __awaiter(void 0, void 0, void 0, function* () {
        const count = ids.length;
        const confirmed = yield confirmStore.prompt(T(count > 1 ? 'CONVERSATIONS_DELETE_CONVERSATION_COUNT' : 'CONVERSATIONS_DELETE_CONVERSATION', {
            count,
        }), undefined, 'error', {
            denyButton: {
                name: T('CANCEL'),
                buttonStyle: 'white',
            },
            confirmButton: {
                name: T('DELETE_COUNT', { count }),
                buttonStyle: 'primary-danger',
                iconName: 'delete',
            },
        });
        if (!confirmed)
            return false;
        deleteConversations(ids);
        return true;
    });
    const promptDeleteConversation = (id) => __awaiter(void 0, void 0, void 0, function* () {
        const confirmed = yield confirmStore.prompt(T('DELETE_NAME', {
            name: conversationsData.value.items.find(x => x.id.toString() == id).name,
        }), undefined, 'error', {
            denyButton: {
                name: T('CANCEL'),
                buttonStyle: 'white',
            },
            confirmButton: {
                name: T('DELETE'),
                buttonStyle: 'primary-danger',
                iconName: 'delete',
            },
        });
        if (!confirmed)
            return;
        deleteConversations(id);
    });
    const deleteConversations = (ids) => __awaiter(void 0, void 0, void 0, function* () {
        const allIds = typeof ids == 'string' ? [ids] : ids;
        yield conversationsApi.delete(allIds);
        fetchConversations();
    });
    const conversations = computed(() => {
        var _a, _b;
        const items = ((_a = conversationsData.value) === null || _a === void 0 ? void 0 : _a.items) || [];
        const unfiltered = items.map((conversation) => {
            return Object.assign(Object.assign({}, conversation), { isCallSuccessful: typeof conversation.isCallSuccessful == 'boolean'
                    ? conversation.isCallSuccessful
                        ? T('CONVERSATIONS_POSITIVE_RESULT')
                        : T('CONVERSATIONS_NEGATIVE_RESULT')
                    : '' });
        });
        if (!((_b = searchStringDebounced.value) === null || _b === void 0 ? void 0 : _b.length)) {
            return unfiltered;
        }
        return unfiltered.map((conversation) => {
            for (const key in conversation) {
                // @ts-ignore
                conversation[key] =
                    typeof conversation[key] != 'string'
                        ? conversation[key]
                        : // @ts-ignore
                            TextStyles.stylePart(conversation[key], searchStringDebounced.value, 'bold');
            }
            return conversation;
        });
    });
    const resetFilters = () => {
        filters.value = {
            searchString: '',
            agents: [],
            sentiments: [],
            callCodes: [],
            campaigns: [],
            divisions: [],
            tags: [],
            statuses: [],
            qmQuestionScores: [],
            conversationDateStart: undefined,
            conversationDateEnd: undefined,
            analysisDateStart: undefined,
            analysisDateEnd: undefined,
            isCallSuccessful: undefined,
            uploadDateStart: undefined,
            uploadDateEnd: undefined,
        };
    };
    const rescoreConversations = (conversationIds) => __awaiter(void 0, void 0, void 0, function* () {
        if (!conversationIds.length) {
            return yield conversationsApi.rescoreAllConversation();
        }
        else {
            return yield conversationsApi.rescoreMultipleConversations(conversationIds);
        }
    });
    const rescoreFilteredConversations = (filterQuery) => __awaiter(void 0, void 0, void 0, function* () {
        return yield conversationsApi.rescoreFromFilteredConversations(filterQuery);
    });
    // automatically updated conversations based on server change notifications 
    onMounted(() => {
        const unsubscribe = useServerMessagesService().subscribe('mangl', msg => {
            var _a;
            const row = (_a = conversationsData.value.items) === null || _a === void 0 ? void 0 : _a.find(row => row.id === msg.recordingId);
            if (!row)
                return;
            // disabled until https://datanext.atlassian.net/browse/DNX-4780 is implemented
            if (msg.analysisDate)
                row.analysisDate = msg.analysisDate;
            // to save the backend some load/complexity, statusses get calculated in the frontend for now
            if (msg.statusId) {
                if (msg.statusId === 100)
                    row.status = 'ready';
                else if (msg.statusId === 101)
                    row.status = 'scored';
                else if (msg.statusId >= 1000)
                    row.status = 'error';
                else
                    row.status = 'loading';
            }
        });
        onUnmounted(unsubscribe);
    });
    const state = {
        promptDeleteConversation,
        promptDeleteConversations,
        conversationsTableProps,
        conversations,
        filters,
        filterPageNumber,
        fetchConversations,
        resetFilters,
        itemCount,
        itemTotal,
        rescoreConversations,
        rescoreFilteredConversations
    };
    provide('conversations-overview', state);
    return state;
};
export const injectConversationOverviewData = () => {
    const state = inject('conversations-overview');
    if (!state) {
        throw 'No available data';
    }
    return state;
};
